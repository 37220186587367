"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";
import Link from "next/link";
import { useAtom } from "jotai/index";
import { accessTokenAtom, sidemenuAtom } from "@/store/global";
import Popup from "@/components/Popup";
import { fetchWithAuth, logout } from "@/utils/token";
import { getLocalStorage } from "@/utils/storage";
import Planet, { PlanetColor } from "@/components/Planet";

const planetSizeArr = [120, 130, 140];

// 행성과 데코의 고정 위치 및 크기 정의
export const planetPositions = [
  { x: 20, y: 10, size: planetSizeArr[2] }, // 1번: 왼쪽 상단, 대형
  { x: 80, y: 50, size: planetSizeArr[1] }, // 2번: 오른쪽 중앙, 중형
  { x: 20, y: 80, size: planetSizeArr[0] }, // 3번: 왼쪽 하단, 소형
  { x: 70, y: 20, size: planetSizeArr[2] }, // 4번: 오른쪽 상단, 대형
  { x: 40, y: 60, size: planetSizeArr[1] }, // 5번: 중앙, 중형
  { x: 90, y: 80, size: planetSizeArr[0] }, // 6번: 오른쪽 하단, 소형
  { x: 30, y: 30, size: planetSizeArr[2] }, // 7번: 왼쪽 중앙, 대형
  { x: 60, y: 90, size: planetSizeArr[1] }, // 8번: 중앙 하단, 중형
  { x: 50, y: 10, size: planetSizeArr[0] }, // 9번: 중앙 상단, 소형
  { x: 10, y: 50, size: planetSizeArr[2] }, // 10번: 왼쪽 중앙, 대형
];

// 데코 아이템의 위치
export const decoPositions = [
  { x: 85, y: 35, size: planetSizeArr[0] },
  { x: 15, y: 65, size: planetSizeArr[0] },
  { x: 75, y: 75, size: planetSizeArr[0] },
];

interface ProfileClientProps {
  id: string;
}

export const POPUP_TYPE = {
  LOGOUT: "LOGOUT",
  MEMBER_OUT: "MEMBER_OUT",
};

export function ProfileClient({ id }: ProfileClientProps) {
  const [sidemenu, setSidemenu] = useState(false);
  const [authToken, setAuthToken] = useAtom(accessTokenAtom);
  const [profileData, setProfileData] = useState<UserProfile>({
    userId: 0,
    nickname: "",
    imageUrl: "",
    introduction: "",
  });
  const [answerData, setAnswerData] = useState([]);
  const [popupType, setPopupType] = useState<string>(POPUP_TYPE.LOGOUT);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [sideMenuPopupOpen, setSideMenuPopupOpen] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const router = useRouter();
  // const [planetPositions, setPlanetPositions] = useState<Array<{ x: number; y: number; size: number }>>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const localId = getLocalStorage("userId");
    if (id || localId) {
      callProfileData(id ? id : (localId as string));
    }
  }, [id]);

  async function getUserData(userId: string) {
    try {
      const response = await fetch(`/api/users/${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      return await response.json();
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  async function getAnswerData(userId: string, currentCursor: string | null) {
    try {
      const url = `/api/users/${userId}/answers${currentCursor ? `?cursor=${currentCursor}` : ""}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-store",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch Answer data");
      }
      return await response.json();
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  const callProfileData = async (userId: string) => {
    const data = await getUserData(userId);
    const userAnswerData = await getAnswerData(userId, null);
    setProfileData(data.data);
    setAnswerData(userAnswerData.data);
    setCursor(userAnswerData.nextCursor);
    setHasMore(userAnswerData.hasMore);
  };

  const loadMoreAnswers = async () => {
    if (!hasMore) return;

    const userId = id || (getLocalStorage("userId") as string);
    const userAnswerData = await getAnswerData(userId, cursor);
    // @ts-ignore
    setAnswerData((prevData) => [...prevData, ...userAnswerData.data]);
    setCursor(userAnswerData.nextCursor);
    setHasMore(userAnswerData.hasMore);
  };

  const popupCallback = (type: string) => {
    if (type === POPUP_TYPE.LOGOUT) {
      logout();
      setSidemenu(false);
      setPopupOpen(false);
      setSideMenuPopupOpen(false);
      setAuthToken(null);
      router.refresh();
    } else if (type === POPUP_TYPE.MEMBER_OUT) {
      //TODO: 회원탈퇴
    }
  };

  const getRandomPosition = useCallback(
    (
      existingPositions: Array<{ x: number; y: number; size: number }>,
      totalItems: number,
    ) => {
      const containerWidth = 100;
      const containerHeight = 100;
      const margin = 1; // 행성 간 최소 거리를 더 줄임 (%)
      const maxSize = Math.min(
        150,
        Math.max(60, Math.floor(500 / Math.sqrt(totalItems))),
      ); // 행성 최대 크기를 더 작게 조정
      const minSize = Math.max(40, maxSize - 30); // 최소 크기도 조정

      let x: number, y: number, size: number;
      let attempts = 0;
      const maxAttempts = 30; // 최대 시도 횟수를 더 줄임

      do {
        x = Math.random() * (containerWidth - 2 * margin) + margin;
        y = Math.random() * (containerHeight - 2 * margin) + margin;
        size = Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize;

        const overlapping = existingPositions.some((pos) => {
          const distance = Math.sqrt((pos.x - x) ** 2 + (pos.y - y) ** 2);
          const minDistance =
            ((pos.size + size) / 2 / containerWidth) * 100 + margin;
          return distance < minDistance;
        });

        if (!overlapping) {
          return { x, y, size };
        }

        attempts++;
        if (attempts >= maxAttempts) {
          // 최대 시도 횟수 초과 시 크기를 줄이고 계속 진행
          size = Math.max(minSize, size * 0.8);
        }
      } while (attempts < maxAttempts * 2); // 전체 시도 횟수 제한

      // 최악의 경우, 겹침을 허용하고 현재 위치 반환
      console.log("Unable to find non-overlapping position, allowing overlap");
      return { x, y, size };
    },
    [],
  );

  const getSecondColor = (firstColor: string) => {
    const colorSet = PlanetColor.find((set) => set[0] === `${firstColor}`);
    return colorSet ? colorSet[1] : PlanetColor[0][1];
  };

  const decorations = [
    "/images/main/deco_1.svg",
    "/images/main/deco_2.svg",
    "/images/main/deco_3.svg",
  ];

  useEffect(() => {
    if (answerData.length > 0 && !isInitialized) {
      const totalItems = answerData.length + decorations.length;
      const newPositions: React.SetStateAction<
        { x: number; y: number; size: number }[]
      > = [];
      for (let i = 0; i < totalItems; i++) {
        newPositions.push(getRandomPosition(newPositions, totalItems));
      }
      // setPlanetPositions(newPositions);
      setIsInitialized(true);
    }
  }, [answerData, isInitialized, getRandomPosition]);

  const memoizedPlanets = useMemo(() => {
    if (!answerData.length) return null;

    return (
      <div className="w-full h-[calc(100vh)] overflow-hidden z-10 relative">
        {answerData.slice(0, 10).map((answer: any, index) => {
          const position = planetPositions[index];
          return (
            <div
              key={index}
              className="absolute transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
              style={{
                left: `${position.x}%`,
                top: `${position.y}%`,
                width: `${position.size}px`,
                height: `${position.size}px`,
              }}
              onClick={() => router.push(`/profile/answer/${answer.answerId}`)}
            >
              <div className="relative w-full h-full">
                <Planet
                  innerColor={`${answer.questionBackgroundColor}`}
                  outerColor={getSecondColor(answer.questionBackgroundColor)}
                  size={position.size}
                />
                <div className="absolute inset-0 flex flex-col justify-center items-center p-2">
                  {answer?.categories?.map((category: any, i: number) => (
                    <span
                      key={i}
                      className="font-GLight text-[17px] text-white line-clamp-1"
                    >
                      #{category.name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
        {decoPositions.map((deco, index) => (
          <div
            key={`deco-${index}`}
            className="absolute transform -translate-x-1/2 -translate-y-1/2"
            style={{
              left: `${deco.x}%`,
              top: `${deco.y}%`,
              width: `${deco.size}px`,
              height: `${deco.size}px`,
            }}
          >
            <Image
              src={`/images/main/deco_${index + 1}.png`}
              alt={`Decoration ${index + 1}`}
              layout="fill"
              objectFit="contain"
              unoptimized={true}
            />
          </div>
        ))}
      </div>
    );
  }, [answerData, router]);

  return (
    <div className="relative mx-auto flex min-h-screen max-w-xl flex-col items-center bg-[#1F1F1F]">
      <Image
        src="/images/main/bg_4.png"
        alt="mainBgImg"
        fill
        className="object-cover"
      />
      {/*navbar*/}
      <div className="fixed z-20 mx-auto flex h-[56px] w-full max-w-xl items-center">
        {/*이전 버튼*/}
        <button
          className="ml-4"
          onClick={() => {
            // router.back();
            router.push("/");
          }}
        >
          <Image
            src="/images/main/ic_arrow_left_ou_b_g90.svg"
            alt="prev"
            width={24}
            height={24}
          />
        </button>

        {/*햄버거 메뉴*/}
        <Image
          src="/images/main/btn_menu.svg"
          alt="menu"
          className="absolute right-4 cursor-pointer"
          width={36}
          height={36}
          onClick={() => {
            setSidemenu(true);
          }}
        />

        {/* 사이드 메뉴 */}
        <div
          className={`fixed right-0 top-0 z-30 h-full w-[260px] bg-white transition-transform duration-500 ease-in-out ${
            sidemenu ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex h-[56px] items-center">
            <Image
              src="/images/main/btn_close.svg"
              alt="closeBtn"
              className="absolute right-4 cursor-pointer"
              width={36}
              height={36}
              onClick={() => {
                setSidemenu(false);
              }}
            />
          </div>
          <div className="">
            <span className="flex h-[30px] items-end px-6 font-GLight text-[#AEAEAE]">
              {"Home"}
            </span>
            <ul className="flex flex-col font-GBold text-[17px] text-[#121212]">
              <li className="flex h-[48px] w-full items-center">
                <Link
                  href={"/profile/edit"}
                  className="flex h-[48px] w-full items-center text-left"
                >
                  <span className="px-6">{"프로필 편집"}</span>
                </Link>
              </li>
              <li className="flex h-[48px] w-full items-center">
                <Link
                  href={"/profile/friends"}
                  className="flex h-[48px] w-full items-center text-left"
                >
                  <span className="px-6">{"친구 목록"}</span>
                </Link>
              </li>
              <li className="flex h-[48px] w-full items-center">
                <button
                  className="h-[48px] w-full text-left"
                  onClick={() => {
                    setPopupType(POPUP_TYPE.LOGOUT);
                    setSideMenuPopupOpen(true);
                  }}
                >
                  <span className="px-6">{"로그아웃"}</span>
                </button>
              </li>
              <li className="flex h-[48px] w-full items-center">
                <button
                  className="text-left text-black/40"
                  onClick={() => {
                    setPopupType(POPUP_TYPE.MEMBER_OUT);
                    setSideMenuPopupOpen(true);
                  }}
                >
                  <span className="px-6">{"탈퇴하기"}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/*프로필카드*/}
      <div className="flex flex-col items-center px-6 pb-4 pt-[72px]">
        <div className="overflow-hidden rounded-full border-2 border-white w-[64px] h-[64px] ">
          <Image
            src={profileData.imageUrl}
            alt="profileImg"
            width={64}
            height={64}
            className="object-corver"
          />
        </div>
        <div className="mt-2 flex flex-col items-center gap-1 text-white">
          <span className="font-GBold text-[19px]">
            {profileData.nickname || ""}
          </span>
          <span className="font-GBold text-[14px] opacity-70">
            {profileData.introduction || ""}
          </span>
        </div>
      </div>

      {/*질문 리스트*/}
      <div className="m-auto w-full">
        {answerData?.length > 0 ? (
          isInitialized ? (
            memoizedPlanets
          ) : (
            <div>Loading...</div>
          )
        ) : (
          <div className="flex w-full flex-col items-center gap-1">
            <span className="font-GBold text-[17px] text-white">
              {"아직 답한 질문이 없어요."}
            </span>
            <div className="text-center font-GLight text-[17px] text-white/70">
              {"친구들과 질문을 주고 받으며\n프로필을 꾸며보세요!"
                .split("\n")
                .map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
            </div>
          </div>
        )}
      </div>

      {/*질문 보기 버튼*/}
      {answerData?.length < 1 && (
        <div className="mb-[30px] w-full px-[40px]">
          <div
            className="flex h-[54px] items-center justify-center rounded-[50px] bg-white py-4 font-GBold text-[19px]"
            onClick={() => {
              router.push("/");
            }}
          >
            {"질문 보기"}
          </div>
        </div>
      )}

      {sideMenuPopupOpen && (
        <Popup className="max-w-[360px] p-[24px]" innerClass="rounded-[16px]">
          <div className="flex w-full flex-col items-start">
            <p className="px-6 pt-6 font-GBold text-[17px] text-[#1F1F1F]">
              {(popupType === POPUP_TYPE.MEMBER_OUT
                ? "정말 탈퇴하시겠어요?"
                : "로그아웃 할까요?"
              )
                .split("\n")
                .map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
            </p>
            {popupType === POPUP_TYPE.MEMBER_OUT && (
              <p className="mt-2 px-6 font-GLight text-[17px] text-[#3D3D3D]">
                {"지금까지 작성한 질문과 답변은 모두 삭제돼요."}
              </p>
            )}
            <hr className="mt-5 w-full text-gray-200" />
            <div className="flex w-full flex-row">
              <>
                <button
                  className="flex h-[56px] w-full items-center justify-center font-GBold text-[#949494]"
                  onClick={() => {
                    setSideMenuPopupOpen(false);
                  }}
                >
                  {"취소"}
                </button>
                <div className="h-[56px] w-[1px] bg-gray-200"></div>
              </>
              <button
                className="flex h-[56px] w-full items-center justify-center font-GBold text-black"
                onClick={() => {
                  popupCallback(popupType);
                  setAuthToken(null);
                  router.replace("/");
                }}
              >
                {popupType === POPUP_TYPE.MEMBER_OUT ? "탈퇴" : "로그아웃"}
              </button>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
}
